import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "header-content" }
const _hoisted_2 = { class: "logo" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "download-container" }
const _hoisted_5 = { class: "footer" }
const _hoisted_6 = { class: "footer-content" }
const _hoisted_7 = { class: "qr-code" }
const _hoisted_8 = ["src"]
const _hoisted_9 = ["src"]

import { ref } from 'vue'
import { useRouter } from 'vue-router'


export default /*@__PURE__*/_defineComponent({
  __name: 'MainLayout',
  setup(__props) {

const router = useRouter()

// 导航菜单数据
const navMenus = [
  {
    path: '/home',
    name: '首页'
  },
  {
    path: '/products',
    name: '产品应用'
  },
  {
    path: '/support',
    name: '技术支持'
  },
  {
    path: '/about',
    name: '关于我们'
  }
]

// 控制下载二维码弹窗
const dialogVisible = ref(false)

// 当前激活的导航
const activeMenu = ref(router.currentRoute.value.path)

const handleClose = () => {
  dialogVisible.value = false
}

// 处理导航点击
const handleNavClick = (path: string) => {
  router.push(path)
}

return (_ctx: any,_cache: any) => {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createBlock(_component_el_container, { class: "layout-container" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_header, { class: "main-header" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("img", {
                src: require('@/assets/titlelog.jpg'),
                alt: "得圣",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push('/')))
              }, null, 8, _hoisted_3)
            ]),
            _createVNode(_component_el_menu, {
              mode: "horizontal",
              "default-active": activeMenu.value,
              class: "nav-menu",
              router: true
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(navMenus, (item) => {
                  return _createVNode(_component_el_menu_item, {
                    key: item.path,
                    index: item.path,
                    onClick: ($event: any) => (handleNavClick(item.path))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.name), 1)
                    ]),
                    _: 2
                  }, 1032, ["index", "onClick"])
                }), 64))
              ]),
              _: 1
            }, 8, ["default-active"]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_el_button, {
                class: "download-btn",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (dialogVisible.value = true))
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode(" 下载手机APP ")
                ])),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_el_main, { class: "main-content" }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      }),
      _createElementVNode("footer", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_el_row, { gutter: 20 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, { span: 12 }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createElementVNode("div", { class: "contact-info" }, [
                    _createElementVNode("h1", { class: "footer-title" }, "联系我们"),
                    _createElementVNode("p", { class: "footer-text" }, "地址：北京市丰台区高立庄616号新华国际中心D座221"),
                    _createElementVNode("p", { class: "footer-text" }, "电话：400-106-6576"),
                    _createElementVNode("p", { class: "footer-text" }, "邮箱: postmaster@dstckj.com")
                  ], -1)
                ])),
                _: 1
              }),
              _createVNode(_component_el_col, { span: 4 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_divider, {
                    direction: "vertical",
                    "border-style": "dashed",
                    class: "footer-divider"
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, { span: 8 }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("img", {
                      src: require('@/assets/wxgz.png'),
                      class: "qr-image"
                    }, null, 8, _hoisted_8)
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "copyright" }, [
            _createElementVNode("p", null, "Copyright © 北京东圣天成科技有限公司     京ICP备2024060857号-3")
          ], -1))
        ])
      ]),
      _createVNode(_component_el_dialog, {
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((dialogVisible).value = $event)),
        title: "二维码下载",
        width: "250",
        "before-close": handleClose,
        center: "",
        class: "qr-dialog"
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: require('@/assets/wxgz.png'),
            class: "dialog-qr"
          }, null, 8, _hoisted_9)
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}
}

})
// src/router/index.ts
import { createRouter, createWebHistory } from 'vue-router'
import MainLayout from '@/views/layout/MainLayout.vue'

const routes = [
  {
    path: '/',
    component: MainLayout,
    children: [
      {
        path: '',
        redirect: '/home'
      },
      {
        path: '/home',
        component: () => import('@/views/home/homePage.vue'),
        meta: { title: '首页' }
      },
      {
        path: '/products',
        component: () => import('@/views/product/productPage.vue'),
        meta: { title: '产品应用' }
      },
      {
        path: '/support',
        component: () => import('@/views/support/supportPage.vue'),
        meta: { title: '技术支持' }
      },
      {
        path: '/about',
        component: () => import('@/views/about/aboutPage.vue'),
        meta: { title: '关于我们' }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router